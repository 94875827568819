import React, {useEffect, useRef, useState} from "react";
import {Option, Select, Text} from "informed";
import styles from './style.module.css';
import cn from 'classnames';
import moment from "moment";
import {useTranslation} from "../../../../contexts/LocaleContext";

const HEIGHT = 35;

const Input = ({className, height=HEIGHT, required, type='text', containerStyle, containerClassName, onFocus=()=>{}, onChange, forceDate, ...other}) => {
  const [currentType, setType] = useState(
    type === 'date' ? 'text' : type
  );

  const ref = useRef(null);

  useEffect(() => {
    if (type === 'date' && ref && ref.current && ref.current.value) {
      setType('date');
    }
  }, []);

  return (
    <div
      style={{position: 'relative', ...containerStyle}}
      className={cn(styles.container, containerClassName)}
    >
        {required && <span className={styles.required}>*</span>}
        <Text
            ref={ref}
            className={cn(styles.input, className)}
            autoComplete={"off"}
            style={{height, borderRadius: height / 2}}
            required={required}
            type={forceDate ? 'date' : currentType}
            onFocus={(e) => {
              onFocus(e);
              if (type === 'date') {
                  setType('date');
                }
            }}
            onChange={onChange}
            {...other}
        />
    </div>
  );
};

export default Input;

export const SelectInput = ({field, height=HEIGHT, placeholder, options, required, containerClassName, noDefaultMargin, ...props}) => (
  <div
    style={{position: 'relative', ...noDefaultMargin && { margin: 0 }}}
    className={cn(styles.container, containerClassName)}
  >
    {required && <span className={styles.required}>*</span>}
    <Select
      className={styles.selectInput}
      field={field}
      required={required}
      style={{height, borderRadius: height / 2}}
      {...props}
    >
      <Option value="" disabled>
        {placeholder}
      </Option>
      {options.map(Option)}
    </Select>
  </div>
);

export const DateInput = ({required, containerClassName, customValue, ...props}) => {
  return(
  <div
    className={cn(styles.container, containerClassName)}
  >
    {required && <span className={styles.required}>*</span>}
    <Input
      parse={(value) => {
        return moment(value).format("YYYY-MM-DDTHH:00:00.000+00:00");
      }}
      format={(value) => moment(value).format("YYYY-MM-DD")}
      type={"date"}
      max={'3000-01-01'}
      forceDate
      {...props}
    />
  </div>
  )
};

export const GenderSelect = ({available = [
        {value: "0", label: 'male'},
        {value: "1", label: 'female'},
    ],
                             ...props}) => {
    const {t} = useTranslation()
    return <SelectInput
        field={'gender'}
        placeholder={t('gender')}
        options={available.map(({value, label})=>({value, label: t(label)}))}
        {...props}
    />
};
