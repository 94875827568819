import Swagger from "swagger-client";
import getToken from "../utils/getToken";
import { BASE_URL } from "../config";

export const POST = async (path, obj, isFormData = false) => {
  const url = BASE_URL + path;

  const body = isFormData ? obj : JSON.stringify(obj);

  const headers = new Headers();
  if (!isFormData) headers.append("Content-Type", "application/json");
  const token = getToken();
  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  const parameters = {
    method: "POST",
    headers,
    body,
  };

  const request = { url, ...parameters };
  console.log("POST request", request, obj);

  try {
    const res = await Swagger.http(request);
    console.log("POST response", res);
    return res;
  } catch (err) {
    console.error("POST error", err.response);
    return err.response;
  }
};

export const PUT = async (path, obj, isFormData = false) => {
  const url = BASE_URL + path;

  const body = isFormData ? obj : JSON.stringify(obj);

  const headers = new Headers();
  if (!isFormData) headers.append("Content-Type", "application/json");
  const token = getToken();
  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  const parameters = {
    method: "PUT",
    headers,
    body,
  };

  const request = { url, ...parameters };
  console.log("PUT request", request, obj);

  try {
    const res = await Swagger.http(request);
    console.log("PUT response", res);
    return res;
  } catch (err) {
    console.error("PUT error", err.response);
    return err.response;
  }
};

export const PATCH = async (path, obj, isFormData = false) => {
  const url = BASE_URL + path;

  const body = isFormData ? obj : JSON.stringify(obj);

  const headers = new Headers();
  if (!isFormData) headers.append("Content-Type", "application/json");
  const token = getToken();
  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  const parameters = {
    method: "PATCH",
    headers,
    body,
  };

  const request = { url, ...parameters };
  console.log("PATCH request", request, obj);

  try {
    const res = await Swagger.http(request);
    console.log("PATCH response", res);
    return res;
  } catch (err) {
    console.error("PATCH error", err.response);
    return err.response;
  }
};

export const GET = async (path) => {
  const url = BASE_URL + path;

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const token = getToken();
  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  const parameters = {
    method: "GET",
    headers,
  };

  const request = { url, ...parameters };
  console.log("GET request", request);

  try {
    const res = await Swagger.http(request);
    console.log("GET response", res);
    return res;
  } catch (err) {
    console.error("GET error", err.response);
    return err.response;
  }
};

export const DELETE = async (path, data = false) => {
  const url = BASE_URL + path;

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const token = getToken();
  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  const parameters = {
    method: "DELETE",
    headers,
  };
  if (data) Object.assign(parameters, { body: JSON.stringify(data) });

  const request = { url, ...parameters };
  console.log("DELETE request", request);

  try {
    const res = await Swagger.http(request);
    console.log("DELETE response", res);
    return res;
  } catch (err) {
    console.error("DELETE error", err.response);
    return err.response;
  }
};

export class NetworkError {
  constructor(res) {
    console.error("NETWORK ERROR:", res);
    this.res = res;
  }
}
