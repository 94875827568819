import {GET} from "../network";
import { localizationEndpoints } from "../requestEndpoints";

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.body.data || JSON.parse(res.data); 
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

export const getDictionary = async (localeCode = 'en') => {
    return await getData(`${localizationEndpoints.get}${localeCode}`)
}

export const getLocales = async () => {
    return await getData(localizationEndpoints.getAllLocales)
}