import {GET} from "../network";
import { signatureEndpoints } from "../requestEndpoints";

const getData = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.body.data || JSON.parse(res.data); 
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

const getFile = async (path, Type) => {
    try {
        const res = await GET(path);
        return res.data;
    } catch (e) {
        if (Type) return new Type();
        return null;
    }
};

export const getDocumentByToken = async (token) => {
    return await getData(`${signatureEndpoints.getDocumentByToken}?token=` + token)
}

export const getSignatureReport = async (id) => {
    return await getFile(signatureEndpoints.getSignatureReport + id)
}