import {useState, useEffect} from 'react';

export default function useMouseDown(ref) {
  const [pressing, setPressing] = useState(false);

  useEffect(() => {
    if (!ref) return;

    const el = ref.current;

    el.addEventListener('mousedown', onMouseDown);

    return () => {
      el.removeEventListener('mousedown', onMouseDown);
    };
  });

  const onMouseDown = (e) => {
    e.stopPropagation();
    setPressing(true);
    document.addEventListener('mouseup', onDocumentMouseUp);
    document.addEventListener('dragend', onDocumentMouseUp);
  };

  const onDocumentMouseUp = (e) => {
    e.stopPropagation();
    setPressing(false);
    document.removeEventListener('mouseup', onDocumentMouseUp);
    document.removeEventListener('dragend', onDocumentMouseUp);
  };

  return pressing;
}
