import { POST, PUT } from "../network";
import { signatureEndpoints } from "../requestEndpoints";

const setData = async (path, data) => {
  try {
    return await POST(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const updateData = async (path, data) => {
  try {
    return await PUT(path, data);
  } catch (e) {
    console.error(path, e);
  }
};

const sendFile = async (path, file, fieldName) => {
    const formData = new FormData();
    formData.append(fieldName ? fieldName : "file", file);
    const res = await POST(path, formData, true);
};

export const attachSignature = async (type = 0, id = 0) => {
    return await setData(signatureEndpoints.attach, { signature_type: type, document_id: id })
}  

export const uploadSignature = async (id = 0, file) => {
    return await sendFile(signatureEndpoints.uploadPicture + id, file)
}

export const getRemoteSigningToken = async id => {
    return await setData(`${signatureEndpoints.generateShareLink}?document_id=${id}`)
}

export const saveSignedDocument = async (token, content) => {
    const res = await setData(signatureEndpoints.saveSignatureDocument, { token: token, content: content })
    return res.body.data || JSON.parse(res.data); 
}

export const initiateSignatureReport = async (docId, signId) => {
  return updateData(signatureEndpoints.initiateSignatureReport, { document_id: docId, signature_id: signId, client_name: "test", client_address: "test", client_contact: "test" })
}

export const generateSignatureReport = async (data) => {
  return await setData(signatureEndpoints.generateSignatureReport, { ...data })
}

export const acceptDocumentRecipient = (documentId) => {
  return setData(signatureEndpoints.acceptDocumentRecipient + documentId)
}

export const wrongDocumentRecipient = (data) => {
  return setData(signatureEndpoints.wrongDocumentRecipient, { ...data })
}

export const refuseSignature = (data) => {
  return setData(signatureEndpoints.refuseSignature, { ...data })
}

export const checkCustomer = (data) => {
  return setData(signatureEndpoints.checkCustomer, { ...data })
}