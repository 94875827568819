import React, { createContext, useContext, useEffect, useState } from 'react'
import { getDictionary, getLocales } from "../api/Localization/dataGetters";
import moment from "moment";
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/ru'
import 'moment/locale/it'
import 'moment/locale/en-gb'
import 'moment/locale/ro'


const defaultTranslations = {
    "current_password": "Current password",
    "new_password": "New Password",
    "repeat_new_password": "Repeat New Password",
    "save": "Save",
    "cancel": "Cancel",
    "close": "Close",

    "you_have_no_catalogues_yet": "You dont have any catalogues yet",
    "no_yet_pt1": "No",
    "your_subscription_has_expired_on_": "Your subscription has expired on ",
    "your_current_subscription_will_expire_on_": "Your current subscription will expire on ",
    "choose_your_subscription_type:": "Choose your subscription type:",
    "unknown_error,_please_try_again_later": "Unknown error, please try again later",
    "card_number": "Card number",
    "your_name": "Your name",
    "pay": "Pay",
    "copyright_2019._all_rights_reserved": "Copyright 2019. All rights Reserved",
    "upload_avatar": "Upload avatar",
    "you_can_upload_only_images": "You can upload only images",
    "_drag_n_drop_your_profile_picture_here,_or_click_to_select": " Drag n drop your profile picture here, or click to select",
    "add_new_": "Add new ",
    "edit": "Edit",
    "_you_can_upload_only_.csv_files": " You can upload only .csv files",
    "_drag_n_drop_your_.csv_file_here,_or_click_to_select": " Drag n drop your .csv file here or click to select",
    "import_.csv": "Import .csv",
    "export_.csv": "export .csv",
    "upload_pdf_catalogue": "Upload PDF catalogue",
    "you_can_upload_only_.pdf_files": "You can upload only .pdf files",
    "_drag_n_drop_your_.pdf_catalogue_here,_or_click_to_select": " Drag n drop your .pdf catalogue here, or click to select",
    "logout": "Logout",
    "confirm_your_email": "Confirm your email",
    "your_template_has_no_variable_fields._please_select_other_one.": "Your template has no variable fields. Please select other one.",
    "some_of_variable_fields_are_deprecated._please,_edit_the_template_then_continue.": "Some of variable fields are deprecated. Please, edit the template then continue.",
    "your_template_has_no_content._would_you_like_to_select_another_one": "Your template has no content. Would you like to select another one",
    "you_have_no_templates_yet._create_one": "You have no templates yet. Create one!",
    "_is_listening._which_template_do_you_want_to_use": " is listening. Which template do you want to use",
    "which_template_do_you_want_to_use": "Which template do you want to use",
    "your_document_is_ready": "Your document is ready",
    "can_you_repeat_please": "Can you repeat please",
    "i_found_this_by_keywords": "I found this by keywords",
    "sorry,_i_found_nothing_by_keywords": "Sorry, I found nothing by keywords",
    "preview": "Preview",
    "select_or_create": "Select or create",
    "select": "Select",
    "name_of_competitor": "Name of competitor",
    "phone": "Phone",
    "email": "Email",
    "what_does_their_company_do": "What does their company do",
    "who_are_their_customers": "Who are their customers",
    "business_facebook_page_link": "Business Facebook page link",
    "enter_comma-separated_keywords": "Enter comma-separated keywords",
    "code": "Code",
    "keywords": "Keywords",
    "call": "Call",
    "note": "Note",
    "meeting": "Meeting",
    "contract": "Contract",
    "invoice": "Invoice",
    "product": "Product",
    "transfer": "Transfer",
    "delivery": "Delivery",
    "created_at": "Created at",
    "type": "Type",
    "next_action_date": "Next action date",
    "customer_activities_pt1": "Customer",
    "customer_activities_pt2": "Activities",
    "person_who_decide": "Person who decide",
    "what_did_they_took_last_time": "What did they took last time",
    "what_does_he/she_do_job_title": "What does he/she do (job title)",
    "facebook_profile": "Facebook profile",
    "date_of_birth": "Date of birth",
    "what_does_he/she_bought": "What does he/she bought",
    "add_new_activity": "Add new activity",
    "edit_activity": "Edit activity",
    "create_new": "Create new",
    "target_market_who_are_your_customers": "Target market (Who are your customers)",
    "target_markets": "Target markets",
    "who_is_your_target_market,_who_are_your_customers_what_do_they_do": "Who is your target market, who are your customers What do they do",
    "products": "Products",
    "catalogues": "Catalogues",
    "price": "Price",
    "u.m._unit_measure": "U.M. (unit measure)",
    "producer": "Producer",
    "general_description": "General description",
    "open": "Open",
    "your_templates": "Your templates",
    "default_templates": "Default templates",
    "latest_documents": "Latest documents",
    "add_variable_fields": "Add variable fields",
    "see_all": "See all",
    "enter_title": "Enter title",
    "copyright_2020_c_all_rights_reserved": "Copyright 2021 (c) All rights reserved",
    "choose_a_name_for_your_virtual_assistant": "Choose a name for your Virtual Assistant",
    "choose_a_voice_you_d__like_for_him/her": "Choose a voice youd  like for him/her",
    "upload_an_avatar_for_your_virtual_assistant": "Upload an avatar for your Virtual Assistant",
    "payment_date": "Payment date",
    "end_date": "End date",
    "amount": "Amount",
    "renew_subscription": "Renew subscription",
    "delete_account": "Delete Account",
    "you_changed_password_successfully!": "You changed password successfully!",
    "passwords_dont_match,_please_check_it": "Passwords dont match, please check it!",
    "templates": "Templates",
    "documents": "Documents",
    "services": "Services",
    "business": "Business",
    "customers": "Customers",
    "partners": "Partners",
    "competitors": "Competitors",
    "How_to": "How To",
    "password_reset,_check_your_email": "Password reset, check your email",
    "reset_password": "Reset password",
    "an_error_occurred": "An error occurred",
    "enter_your_email": "Enter your email",
    "incorrect_login_or_password": "Incorrect login or password",
    "name": "Name",
    "surname": "Surname",
    "sign_up": "Sign Up",
    "sign_in": "Sign In",
    "login": "Login",
    "password": "Password",
    "forgot_password": "Forgot password",
    "remember_me": "Remember me",
    "work_email": "Work email",
    "company_name": "Company name",
    "agree_with": "Agree with ",
    "terms": "Terms",
    "and": "and",
    "policy": "Policy",
    "business_model": "Business model",
    "description_of_business_model_ex:_production_business,_retail_business_service_supply_business": "Description of business model ex: production business, retail business service supply business",
    "year_average_or_final_production_capacity": "Year average or final production capacity",
    "trasportation_methods_and_conditions": "Trasportation methods and conditions",
    "delivery_locations": "Delivery locations",
    "years,_locations,_domains_of_experience": "Years, locations, domains of experience",
    "experience": "Experience",
    "delivery_model": "Delivery model",
    "geographical_locations_and_possibility_to_deliver_the_goods": "Geographical locations and possibility to deliver the goods",
    "business_mission_and_vision": "Business mission and vision",
    "2_propositions": "2 sentences ",
    "street": "Street",
    "city": "City",
    "country": "Country",
    "?": "?",
    "index": "Index",
    "state": "State",
    "company_registration_number": "Company registration number",
    "date_of_establishment": "Date of establishment",
    "type_of_license,_issued": "Type of license, issued",
    "certification": "Certification",
    "authorizations": "Authorizations",
    "Quality_certificates,_issued": "Quality certificates, issued",
    "brand_certificates_and_copyrights,_issued": "Brand certificates and copyrights, issued",
    "banking_details": "Banking details",
    "text_field_max_3_propositions_about_founders_and_type_of_company": "Text field max 3 propositions about founders and type of company",
    "alternative_email": "Alternative email",
    "twitter_profile": "Twitter profile",
    "linked-in_profile": "Linked-in profile",
    "profile": "Profile",
    "check_your_email_to_confirm_registration": "Check your email to confirm registration",
    "start_using_fastboss": "Start using fastboss",
    "fill_in_later": "Fill in later",
    "register": "Register",
    "next_step": "Next step",
    "registration_form": "Registration form",
    "congratulations!": "Congratulations!",
    "today_date": "Today date",
    "date_of_completion_of_the_document": "Date of completion of the document",
    "issue_number": "Issue number",
    "issue_number_desc": "Counter, the output number of the document based on the history of all documents issued by the system, format (no next from previous this template slash issue data: {previous + 1 / issue data})",
    "my_company_name": "My company name",
    "my_company": "My company",
    "my_company_address": "My company address",
    "my_company_email": "My company email",
    "my_bank_details": "My bank details",
    "my_payment_conditions": "My payment conditions",
    "my_payments_conditions_desc": "Text with payment conditions predetermined by the user's company",
    "my_name": "My name",
    "my_name_desc": "Full name of the user",
    "my_phone": "My phone",
    "my_email": "My email",
    "product_name": "Product name",
    "product_name_desc": "Choose one or several of your products",
    "product_price": "Product price",
    "product_price_desc": "Products price, in case it is not yet provided in the list of the products. In case it is already in the list, this will be filled automatically.",
    "product_producer": "Product producer",
    "product_delivery": "Product delivery",
    "product_delivery_desc": "Your product delivery method.  In case it is already in the list, this will be filled automatically.",
    "product_description": "Product description",
    "product_description_desc": "Your product general description.  In case it is already in the list, this will be filled automatically.",
    "service_": "Service ",
    "service_name": "Service name",
    "service_price": "Service price",
    "service_price_desc": "Services price, in case it is not yet provided in the list of the products. In case it is already in the list, this will be filled automatically.",
    "service_delivery": "Service delivery",
    "service_delivery_desc": "Your service delivery method.  In case it is already in the list, this will be filled automatically.",
    "service_description": "Service description",
    "service_description_desc": "Your service general description.  In case it is already in the list, this will be filled automatically.",
    "Quantity": "Quantity",
    "Quantity_desc": "Total amount offered, which has to be introduced in the measure specific to the product, so that the total price can be calculated by multiplying the volume by the price. When there are discounts, they have to be subtracted from the total price.",
    "discount": "Discount",
    "discount_desc": "Discount applied on specific customers. It will be asked to provide it from the start either in numbers or percentage.",
    "total": "Total",
    "total_desc": "Formula of the total sum calculation placed in offer PRICE*AMOUNT-DISCOUNT",
    "client": "Client",
    "client_name": "Client name",
    "client_name_desc": "Name of the user or his company",
    "client_contact_person_desc": "Contacts of the contact person from the company. (For B2P is client name)",
    "client_address": "Client address",
    "client_address_desc": "Address of the user",
    "client_email": "Client email",
    "client_email_desc": "Email of the client to which the letter is sent",
    "client_phone_": "Client phone ",
    "client_phone_desc": "Phone of the client to which the letter is sent",
    "client_activity": "Client activity",
    "client_activity_desc": "What does their company do",
    "client_bank_details": "Client bank details",
    "client_bank_details_desc": "Bank details of the client",
    "client_products": "Client products",
    "client_products_desc": "What products client has",
    "partner_name_": "Partner name ",
    "partner_name_desc": "Partner full name",
    "patrner_contact_person": "Patrner contact person",
    "partner_contact_person_desc": "Contacts of the contact person from the company",
    "partner": "Partner",
    "partner_address": "Partner address",
    "partner_email": "Partner email",
    "partner_email_desc": "Email of the partner to which the letter is sent",
    "partner_phone": "Partner phone",
    "partner_phone_desc": "Phone of the partner to which the letter is sent",
    "partner_activity": "Partner activity",
    "partner_activity_desc": "What does their company do",
    "partner_needs": "Partner needs",
    "partner_needs_desc": "What does the partner need",
    "partner_customers": "Partner customers",
    "partner_facebook": "Partner facebook",
    "payment_period_or_credits": "Payment period or credits",
    "payment_period_or_credits_desc": "Payment period offered",
    "any_number": "Any number",
    "any_date": "Any date",
    "any_text": "Any text",
    "any_number_desc": "Variable used anywhere, either it is amount of days, persons, or any other number used within the document.",
    "any_date_desc": "Date specific anywhere in text, which can be different no matter the client or document, for example: date of the meeting.",
    "any_text_desc": "Alternative text used anywhere in documents, which can be different no matter the client or document, depending on situation",
    "select_products": "Select your products or create one",
    "select_services": "Select your services or create one",
    "select_customer": "Select your customer or create one",
    "select_partner": "Select your partner create one",
    "address": "Address",
    "what_is_the_product": "What is the product",
    "price_of_which_product": "Price of which product",
    "delivery_of_which_product": "Delivery of which product",
    "producer_of_which_product": "Producer of which product",
    "description_of_which_product": "Description of which product",
    "what_is_the_service": "What is the service",
    "price_of_which_service": "Price of which service",
    "delivery_of_which_service": "Delivery of which service",
    "description_of_which_service": "Description of which service",
    "what_is_the_total_price": "What is the total price",
    "what_is_your_bank_account": "What is your bank account",
    "what_is_your_payment_conditions": "What are your payment conditions",
    "what_is_your_advance_payment_%": "What is your advance payment (%)",
    "what_is_the_price_of_the_product_you_offer_to_the_customer": "what_is the price of the product you offer to the customer",
    "what_discount_do_you_apply_on_this_offer": "What discount do you apply on this offer",
    "what_is_the_total_quantity_your_customer_need": "What is the total quantity your customer need",
    "please,_provide_your_customer_bank_account,_for_the_better_experience_please_type_in_the_bank_account_details": "Please, provide your customer bank account, for the better experience please type in the bank account details",
    "what_products_or_services_does_your_customer_sell": "What products or services does your customer sell",
    "what_is_your_delivery_method": "What is your delivery method",
    "what_is_the_delivery_period": "What is the delivery period",
    "what_is_the_payment_period_you_offer_for_this_customer": "What_is the payment period you offer for this customer",
    "please_provide_for_me_this_specific_number": "Please provide for me this specific number",
    "please_provide_for_me_this_specific_text": "Please provide for me this specific text",
    "please_provide_for_me_this_specific_date": "Please provide for me this specific date",
    "client_contact_person": "",
    "no_yet_pt2": "yet",
    "i_need_technical_support": "I need technical support",
    "type_me_what_issues_do_you_have": "Type me what issues do you have",
    "tell_me_what_issues_do_you_have": "Tell me what issues do you have",
    "do_you_want_to_attach_some_screenshots": "Do you want to attach some screenshots",
    "yes_i_do": "Yes I do",
    "no_i_do_not": "No I do not",
    "do_you_want_to_be_contacted_by_phone": "Do you want to be contacted by phone",
    "please_enter_your_phone_number_in_the_following_format_country_code_your_number": "Please enter your phone number in the following format COUNTRY CODE - YOUR NUMBER",
    "i_have_sent_your_issues_to_the_support_team_they_will_contact_you_shortly" : "I have sent your issues to the support team, they will contact you shortly",
}

const getLang = () => (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';

const contextShape = {
    translations: defaultTranslations,
    currentLocale: 'en',
    setLocale: async (localeCode = 'en') => { },
    locales: {},
    setLocales: () => {},
    isSetLocale: false
}

export const LocaleContext = createContext(contextShape)


export const useLocaleContext = () => {
    const [state, setState] = useState(contextShape)

    const setLocale = async (localeCode = 'en') => {
        setState((prev) => ({
            ...prev,
            isSetLocale: false
        }))

        const resp = await getDictionary(localeCode)
        moment.locale(localeCode);
        if (resp) {
            setState(prevState => ({ ...prevState, translations: resp[localeCode], currentLocale: localeCode }))
            localStorage.setItem('USER_LANG', localeCode)
        }

        setState((prev) => ({
            ...prev,
            isSetLocale: true
        }))
    }

    const setLocales = async (locales = [{ en: 'English' }]) => {
        setState(prevState => ({
            ...prevState, locales: locales
        }))
    }

    useEffect(() => {
        setLocale()

        const setters = {
            setLocale,
            setLocales
        }
        const initialState = Object.assign(state, setters)
        setState(initialState)
    }, [])

    return state
}

export const useTranslation = () => {
    const dictionary = useContext(LocaleContext)
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    const translationKeyToText = (key = '') => {
        const keysWithSpaces = key.split('._').join('. _')
        const keysSplitted = keysWithSpaces.split(/\s(_[^]+)/)
        const keysCapitalized = keysSplitted
          .map((current, index) =>
            index === 0 || current[0] === '_'
              ? capitalizeFirstLetter(current[0] === '_' ? current.slice(1) : current)
              : current)
          .join(' ')
        const keysWithoutUnderscore = keysCapitalized.split('_').join(' ')
        return keysWithoutUnderscore.trim()
    }
    const textToTranslationKey = (text) => text.toLowerCase().replace(/\s/g, "_")
    const t = (key = '') => {
        const _key = key.includes('_') ? key.toLowerCase() : key.trim().replace(/ /g, '_').toLowerCase().replace(/\?/g, '')

        const missingTranslations = JSON.parse(localStorage.getItem('MISSING_TRANS')) || []
        if (!missingTranslations.includes(_key)) localStorage.setItem('MISSING_TRANS', JSON.stringify([...missingTranslations, _key]))

        const keyInDictionary = dictionary.translations?.[_key]
        if (keyInDictionary) return keyInDictionary

        const translationKey = textToTranslationKey(key)
        return dictionary.translations?.[translationKey] || translationKeyToText(key)
    }
    const setLocale = dictionary.setLocale
    const currentLocale = dictionary.currentLocale

    const initLocale = async () => {
        const savedLocale = localStorage.getItem('USER_LANG')
        const possibleLocales = await getLocales() || {}
        dictionary.setLocales(Object.entries(possibleLocales))

        if (savedLocale) {
            const _locale = Object.keys(possibleLocales).includes(savedLocale) ? savedLocale : 'en'
            return await setLocale(_locale)
        }
        else {
            const possibleLocale = getLang()
            const _locale = Object.keys(possibleLocales).includes(possibleLocale) ? possibleLocale : 'en'
            return await setLocale(_locale)
        }
    }


    return { t, setLocale, currentLocale, initLocale, locales: dictionary.locales }
}

