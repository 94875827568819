import React, { Suspense } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { YMInitializer } from "react-yandex-metrika";

import RemoteSignature from "./components/RemoteSignature";
import { LocaleContext, useLocaleContext } from "./contexts/LocaleContext";

const App = React.lazy(() => import("./components/App"));

const Splitter = () => {
  const dictionary = useLocaleContext();

  return (
    <>
      <YMInitializer
        accounts={[86338175]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        }}
        version="2"
      />
      <Suspense fallback={<div />}>
        <Router>
          <LocaleContext.Provider value={dictionary}>
            <Switch>
              <Route path={"/sign/:token"} render={() => <RemoteSignature />} />
              <Route path={"*"} render={() => <App />} />
            </Switch>
          </LocaleContext.Provider>
        </Router>
      </Suspense>
    </>
  );
};
export default Splitter;
