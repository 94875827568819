const getToken = () => {
  return (
    window.localStorage.getItem("token") ||
    window.sessionStorage.getItem("token")
  );
};

export const removeToken = () => {
  window.localStorage.removeItem("token");
  window.sessionStorage.removeItem("token");
};

export default getToken;
