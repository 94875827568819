import React, { useEffect, useRef, useState } from "react";
import { Form } from "informed"
import { useParams } from "react-router-dom";
import platform from "platform";
import Modal from "react-bootstrap/Modal"
import html2pdf from "html2pdf.js";
import styles from "./styles.module.css";
import { getDocumentByToken } from "../../api/Signature/dataGetters";
import logo from "../Dashboard/images/logo.png";
import { useTranslation } from "../../contexts/LocaleContext";
import Input from "../common/core/Input";
import Button from "../common/core/Button";
import SignatureCanvas from "react-signature-canvas";
import resizeBase64 from "../../utils/base64resize";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { saveSignedDocument, generateSignatureReport, checkCustomer } from "../../api/Signature/dataSetters";
import { Editor as MCEditor } from "@tinymce/tinymce-react";
import {
  SIGNATURE_RESOLUTION,
  SIGNATURE_SIZE,
  TINY_API_KEY,
} from "../../config";
import { refuseSignature } from "../../api/Signature/dataSetters";

const RemoteSignature = () => {
  const { t, initLocale } = useTranslation();
  const { token } = useParams();
  const [document, setDocument] = useState(null);
  const [listItem, setListItem] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [stage, setStage] = useState(0);
  const [recognitionTries, setRecognitionTries] = useState(3);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(true);

  const handleClose = () => setOpenModal(false);

  const [isLocaleSet, setLocaleSet] = useState(false)

  useEffect(()=>{
    setLocale()
  },[])

  const setLocale = async () => {
    setLocaleSet(false)
    await initLocale()
    setLocaleSet(true)
  }

  const reportData = {
    client_name: listItem?.name  || "", 
    client_address: listItem?.fulladdress || "", 
    client_contact_person: listItem?.contactperson || "", 
    client_device_model: platform.product || platform.os.family, 
    used_token: token,  
    document_id: document?.id, 
    used_device: platform.description
  }

  const onSubmitCheckCustomer = async (state) => {
      await checkCustomer({
        ...state,
        model_type: listItem?.model_type,
        customer_type: listItem?.type ? listItem?.model_type : 0,
        document_id: document?.id
      }).then((res) => {
        if (!res.ok) return setRecognitionTries((prev) => prev - 1);

        handleClose();
      })
  }

  useEffect(() => {
    if (recognitionTries !== 0) return;

    setStage(3)
  }, [recognitionTries])

  const handleRefuseToSign = async () => {
    await refuseSignature(reportData)
    window.location.reload(); 
  }

  const canvas = useRef(null);

  const { height, width } = useWindowDimensions();

  const isPortrait = () => {
    return height >= width;
  };

  const fetchDocument = async () => {
    setLoading(true);
    const doc = await getDocumentByToken(token);
    if (doc) {
      if (!doc.content) setError(t("This_document_completed"));
      else {
        setDocument(doc)
        for (const [key, value] of Object.entries(doc)) {
          if ((key === "person_customers" || key === "business_customers" || key === "partners") && value.length > 0) {
            setListItem(value[0])
          } 
        }
      }
    } else {
      setError(t("document_is_signed_link_expired"));
    }
    setLoading(false);
  };

  function saveAsPdf() {
    const opt = {
      filename: document?.title + ".pdf",
      margin: [20, 30, 20, 15],
      jsPDF: { unit: "mm", format: "letter", orientation: "p" },
    };
    html2pdf().from(document?.content).set(opt).toPdf().save();
  }

  useEffect(() => {
    fetchDocument();
  }, [token]);

  useEffect(() => {
    if (canvas?.current) canvas.current.clear();
  }, [width, height]);

  const clear = () => {
    if (canvas?.current) {
      canvas.current.clear();
    }
  };

  const save = async () => {
    if (canvas?.current) {
      //getting img
      const base64String = await canvas.current.toDataURL("image/jpeg");
      // compressing
      const compressedImg = await resizeBase64(
        base64String,
        SIGNATURE_RESOLUTION.W,
        SIGNATURE_RESOLUTION.H
      );
      const newContent = document.content.replaceAll(
        "{AWAITING_SIGNATURE}",
        `<img src="${compressedImg}"  width="${SIGNATURE_SIZE.W}" height="${SIGNATURE_SIZE.H}" />`
      );
      setDocument((doc) => {
        return { ...doc, content: newContent };
      });
      const data = await saveSignedDocument(token, newContent);
      if (data) {
        const clientSignature = data?.signatures.filter((item) => item.signature_type === 1)

        await generateSignatureReport({
          client_name: listItem?.name  || "", 
          client_address: listItem?.fulladdress || "", 
          client_contact_person: listItem?.contactperson || "", 
          client_device_model: platform.product || platform.os.family, 
          customer_type: 1,
          signature_id: clientSignature[0]?.id, 
          used_token: token,  
          document_id: clientSignature[0]?.document_id, 
          used_device: platform.description
        })
      }
      setStage(2);
    }
  };

  const docPrevWidth = width < 700 ? "100%" : "210mm";

  const screen = (stage) => {
    switch (stage) {
      case 0:
      case 2:
        return (
          <>
            <div className={styles.title}>
              {stage === 0 ? t("you_are_signing") : t("you_have_signed")}  
              "{document?.title}"
            </div>
            <div className={styles.editorContainer}>
              <MCEditor
                apiKey={TINY_API_KEY}
                value={document?.content}
                disabled={true}
                init={{
                  max_width: "210mm",
                  max_height: height - 250,
                  min_height: height - 250,
                  width: docPrevWidth,
                  toolbar_mode: "wrap",
                  menubar: "",
                  autoresize_overflow_padding: 50,
                  branding: false,
                  content_style: 'body { user-select: none; pointer-events: none; }'
                }}
                plugins={
                  "table hr pagebreak fullscreen print autoresize quickbars imagetools image"
                }
                toolbar={[]}
              />
            </div>
            <div className={styles.buttons}>
              {stage === 0 ? (
                <>
                  <Button
                    className={styles.refuseButton}
                    title={t("refuse to sign")}
                    onClick={handleRefuseToSign}
                  />
                  <Button
                    className={styles.signButton}
                    title={t("ok_sign")}
                    onClick={() => setStage(1)}
                  />
                </>
              ) : (
                <Button
                  className={styles.signButton}
                  title={t("save_as_doc")}
                  onClick={saveAsPdf}
                />
              )}
            </div>
            <UserRecognitionModal openModal={openModal} handleClose={handleClose} onSubmitCheckCustomer={onSubmitCheckCustomer} recognitionTries={recognitionTries} listItem={listItem}/>
          </>
        );
      case 1:
        return (
          <>
            {width < 600 && width <= height && <Rotate />}
            <div
              className={
                width <= 700 && height <= 400
                  ? styles.signatureWrapperSmall
                  : styles.signatureWrapper
              }
            >
              <SignatureCanvas
                ref={canvas}
                penColor={"#3c4a85"}
                canvasProps={{
                  width: Math.min(width - 16, 600),
                  height: Math.min(width / 2 - 8, 300),
                  className: styles.canvas,
                }}
                backgroundColor={"#fff"}
                velocityFilterWeight={0.5}
              />
            </div>
            <div
              className={
                width < 700 && width > height
                  ? styles.padButtonsSmall
                  : styles.padButtonsBig
              }
            >
              <Button
                className={styles.signButton}
                title={t("clear")}
                onClick={clear}
                color={"#f34242"}
              />
              <Button
                className={styles.signButton}
                title={t("sign")}
                onClick={save}
              />
            </div>
          </>
        );
      case 3: 
        return (
          <div className={styles.unauthorizedContainer}>
            {t("sorry you are not authorized")}
          </div>
        )
    }
  };

  return (
    <div className={styles.main}>
      {height > 500 && <Header />}
      <div className={styles.body}>
        <div className={styles.wrapper}>
          {isLoading && !isLocaleSet ? (
            <div>loading</div>
          ) : error ? (
            <div>{error}</div>
          ) : (
            screen(stage)
          )}
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <img src={logo} alt={"logo"} />
      </div>
      <div className={styles.more}>
        <a className={styles.link} href={"http://fastboss.ai"} target={"blank"}>
          {t("Learn more")}
        </a>
      </div>
    </div>
  );
};

const Rotate = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.rotateWrapper}>
      <i className={"fas fa-sync-alt"} />
      <div>{t("Rotate_your_device")}</div>
    </div>
  );
};

const UserRecognitionModal = ({onSubmitCheckCustomer = () => {}, handleClose = () => {}, recognitionTries, openModal, listItem}) => {
  const { t } = useTranslation()

  return (
    <Modal 
      show={openModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      backdropClassName={styles.backdrop}
    >
      <Form onSubmit={onSubmitCheckCustomer}>
        <Modal.Header className="justify-content-center">
          <Modal.Title>
            {t("title authorization")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
            <div className={styles.modalAlertDescription}>
              {t("authorization description")}
            </div>
            <div className={styles.errorMessageModal}>
              {t("authorization tries left")}: {recognitionTries}
            </div>
            <Input placeholder={t('Your name surname')} field={'customer_name'}/>
            {listItem?.type !== 1 ? <Input placeholder={t('Company name')} field={'customer_company_name'}/> : null}
        </Modal.Body>
        <Modal.Footer>
          <Button title={t("Submit")} variant="primary"/>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default RemoteSignature;
