export const GOOGLE_CLOUD_API_KEY = "AIzaSyCJ2FYT1rLwkTglCZMbB5utMD1FDy4erUE";
export const BASE_URL = "https://api.fastboss.ai";
export const SIGNATURE_BASE_URL = "https://beta.fastboss.ai/#/sign/";
export const STRIPE_PUBLIC_KEY = "pk_live_BfshhNfPc8nebRLCETd9HvMI00bytTqWg6";
export const TINY_API_KEY = "9159yodratak78legy75t7q1wvqs2n03hpxutp00vxorj632";

export const AVATAR_COMPRESSION = {
  MAX_DIMS: 512,
  QUALITY: 0.8,
};

export const SIGNATURE_SIZE = {
  W: 200,
  H: 100,
};

export const SIGNATURE_RESOLUTION = {
  W: 300,
  H: 150,
};
