import useMouseDown from "../../hooks/useMouseDown";
import styles from './style.module.css';
import cn from 'classnames';
import React, {createRef, useRef} from "react";

const TouchableOpacity =  React.forwardRef(({children, className, underlyingColor='transparent', disabled = false, ...otherProps}, ref) => {
  //
  ref = ref ? ref : createRef()
  const pressing = useMouseDown(ref);

  return (
    <div
      className={cn(styles.element, (pressing && !disabled) && styles.pressing, className)}
      ref={ref}
      {...otherProps}
    >
      {children}
    </div>
  );
});

export default TouchableOpacity;
