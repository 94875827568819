import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import Splitter from "./Splitter";

// Sentry.init({
//     dsn: "https://d1dba69184414dd790eebe39566a44c2@o1153180.ingest.sentry.io/6232099",
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 1.0,
// });

ReactDOM.render(<Splitter />, document.getElementById('root'));
